// extracted by mini-css-extract-plugin
export var column = "ProviderBrochureShowContact__column__l8RX6";
export var envelopeImage = "ProviderBrochureShowContact__envelopeImage__LpyxN";
export var flex = "ProviderBrochureShowContact__flex__pKCT4";
export var flexColumn = "ProviderBrochureShowContact__flexColumn__r_qAz";
export var gap1 = "ProviderBrochureShowContact__gap1__UMHGm";
export var gap2 = "ProviderBrochureShowContact__gap2__n1kTd";
export var gap3 = "ProviderBrochureShowContact__gap3__rhqsw";
export var gap4 = "ProviderBrochureShowContact__gap4__JAeXz";
export var gap5 = "ProviderBrochureShowContact__gap5__MAVc3";
export var providerContactContainer = "ProviderBrochureShowContact__providerContactContainer__cnYdm";
export var providerContactEnvelope = "ProviderBrochureShowContact__providerContactEnvelope___G82b";
export var providerContactHeader = "ProviderBrochureShowContact__providerContactHeader__K5ha3";
export var providerContactJobTitle = "ProviderBrochureShowContact__providerContactJobTitle__EL3si";
export var providerContactMessage = "ProviderBrochureShowContact__providerContactMessage__NGBBK";
export var providerContactMessageSub = "ProviderBrochureShowContact__providerContactMessageSub__DRd1K";
export var providerContactName = "ProviderBrochureShowContact__providerContactName__l49TY";
export var providerContactPerson = "ProviderBrochureShowContact__providerContactPerson__h6eOZ";
export var providerContactPersonImage = "ProviderBrochureShowContact__providerContactPersonImage__aoEZ9";
export var providerContactSubmitted = "ProviderBrochureShowContact__providerContactSubmitted__jNqzO";
export var providerContactSubtitle = "ProviderBrochureShowContact__providerContactSubtitle__wfWrL";
export var providerContactSummary = "ProviderBrochureShowContact__providerContactSummary__c_asu";
export var providerContactTitle = "ProviderBrochureShowContact__providerContactTitle__kmo_l";
export var row = "ProviderBrochureShowContact__row__YpZgk";
export var salesManagerImage = "ProviderBrochureShowContact__salesManagerImage__iYq_G";