// extracted by mini-css-extract-plugin
export var column = "ProviderBrochureShow__column__uNigR";
export var contactUsContainer = "ProviderBrochureShow__contactUsContainer__Qsqli";
export var container = "ProviderBrochureShow__container__r6nkA";
export var flex = "ProviderBrochureShow__flex__iUbI4";
export var flexColumn = "ProviderBrochureShow__flexColumn__Abd8g";
export var gap1 = "ProviderBrochureShow__gap1__RHc3T";
export var gap2 = "ProviderBrochureShow__gap2__kMSAO";
export var gap3 = "ProviderBrochureShow__gap3__YiBrI";
export var gap4 = "ProviderBrochureShow__gap4__au1sO";
export var gap5 = "ProviderBrochureShow__gap5__HWu_4";
export var mainContentContainer = "ProviderBrochureShow__mainContentContainer__lP9va";
export var row = "ProviderBrochureShow__row__R8DP2";
export var sidebarContainer = "ProviderBrochureShow__sidebarContainer__bYLs5";