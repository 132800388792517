// extracted by mini-css-extract-plugin
export var column = "ProviderBrochureShowSidebar__column__YKKhv";
export var flex = "ProviderBrochureShowSidebar__flex__v8hEw";
export var flexColumn = "ProviderBrochureShowSidebar__flexColumn__KSGw5";
export var gap1 = "ProviderBrochureShowSidebar__gap1__gxGmH";
export var gap2 = "ProviderBrochureShowSidebar__gap2__hd9lY";
export var gap3 = "ProviderBrochureShowSidebar__gap3__E7Rw7";
export var gap4 = "ProviderBrochureShowSidebar__gap4__LViMx";
export var gap5 = "ProviderBrochureShowSidebar__gap5__Msa4b";
export var imageContainer = "ProviderBrochureShowSidebar__imageContainer__DZ53J";
export var providerDetail = "ProviderBrochureShowSidebar__providerDetail__JXcmd";
export var providerSummary = "ProviderBrochureShowSidebar__providerSummary__Tjwd0";
export var row = "ProviderBrochureShowSidebar__row__iupg1";
export var sidebar = "ProviderBrochureShowSidebar__sidebar__ZhiXn";
export var summaryTitle = "ProviderBrochureShowSidebar__summaryTitle__xCfRb";