// extracted by mini-css-extract-plugin
export var breadcrumbs = "ProviderBrochureShowContent__breadcrumbs__RQ_ZL";
export var column = "ProviderBrochureShowContent__column__rqFkK";
export var content = "ProviderBrochureShowContent__content__b4FR1";
export var flex = "ProviderBrochureShowContent__flex__uQEXv";
export var flexColumn = "ProviderBrochureShowContent__flexColumn__jw2dW";
export var gap1 = "ProviderBrochureShowContent__gap1__CWdx6";
export var gap2 = "ProviderBrochureShowContent__gap2__wpRBy";
export var gap3 = "ProviderBrochureShowContent__gap3__QXFZO";
export var gap4 = "ProviderBrochureShowContent__gap4__eO8iX";
export var gap5 = "ProviderBrochureShowContent__gap5__sPFtf";
export var row = "ProviderBrochureShowContent__row__YlkJI";
export var sectionTitle = "ProviderBrochureShowContent__sectionTitle__U6lft";
export var subtitle = "ProviderBrochureShowContent__subtitle__y9616";
export var title = "ProviderBrochureShowContent__title__NVOD1";